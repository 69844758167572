import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { USPCard } from "@/components/USPCard";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Grid } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

const TextUspSection = ({ slice }) => (
  <Section variant={slice.variation === "default" ? undefined : slice.variation} position="relative" overflow="hidden">
    <SectionHeading>{slice.primary.title}</SectionHeading>
    <SectionDescription>
      <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
    </SectionDescription>
    <Grid gridTemplateColumns={{ base: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)" }} gap={16}>
      {slice?.items?.map(({ usp }) => {
        if (!usp.data) return null;

        return (
          <USPCard
            key={usp.data.title}
            description={usp.data.description}
            title={usp.data.title}
            image={usp.data.image}
          />
        );
      })}
    </Grid>
  </Section>
);

TextUspSection.displayName = TextUspSection;

export default TextUspSection;
