import { fitMapBounds } from "@/utils/fitMapBounds";
import * as React from "react";
import { BrandedPin } from "../BrandedPin";
import { Map } from "../Map";
import type { MapProps } from "../Map/Map";

const CITIES = [
  { name: "Berlin", lat: 52.52, lng: 13.405, slug: "berlin" },
  { name: "Copenhagen", lat: 55.6761, lng: 12.5683, slug: "copenhagen" },
  { name: "Munich", lat: 48.1351, lng: 11.582, slug: "munich" },
  { name: "Paris", lat: 48.8566, lng: 2.3522, slug: "paris" },
  { name: "Hamburg", lat: 53.5488, lng: 9.9872, slug: "hamburg" },
  { name: "Aarhus", lat: 56.1629, lng: 10.2039, slug: "aarhus" },
  { name: "Oslo", lat: 59.9139, lng: 10.7522, slug: "oslo" },
];

const createMapOptions = () => {
  return {
    panControl: false,
    scrollWheel: false,
    zoomControl: false,
    fullscreenControl: false,
  };
};

export const CityMap = (props: MapProps) => (
  <Map
    w={{ base: "100%", xl: "524px" }}
    h={{ base: "343px", sm: "448px", md: "516px", lg: "684px", xl: "400px" }}
    center={{ lat: 0, lng: 0 }}
    zoom={4}
    options={createMapOptions}
    borderRadius="lg"
    overflow="hidden"
    onGoogleApiLoaded={({ map }: any) => fitMapBounds({ map, pins: CITIES })}
    yesIWantToUseGoogleMapApiInternals
    {...props}
  >
    {CITIES.map((city) => (
      <BrandedPin key={city.slug} lat={city.lat} lng={city.lng} href={`/${city.slug}/homes`} />
    ))}
  </Map>
);
