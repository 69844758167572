import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { UspList } from "@/components/UspList";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Box, Grid } from "@chakra-ui/react";
import Image from "next/legacy/image";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";
import scribble from "../../../public/scribble1.png";

const CompanyUspSection = ({ slice }) => (
  <Section variant={slice.variation === "default" ? "white" : slice.variation} position="relative" overflow="hidden">
    <SectionHeading>{slice.primary.title}</SectionHeading>
    <SectionDescription>
      <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
    </SectionDescription>
    <Box position="absolute" display={{ base: "none", lg: "block" }} top={{ lg: "-40px" }} right={{ lg: "-140px" }}>
      <Image src={scribble} alt="" width={440} height={340} />
    </Box>
    <Grid
      gridTemplateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
      gridColumnGap={{ md: 10, xl: 32 }}
      gridRowGap={16}
    >
      {slice?.items?.map(({ uspList }) => {
        if (!uspList.data) return null;
        return (
          <UspList
            key={uspList.data.title}
            zIndex={1}
            uspList={{
              title: uspList.data.title,
              image: uspList.data.image,
              usps: uspList.data.usps,
            }}
          />
        );
      })}
    </Grid>
  </Section>
);

CompanyUspSection.displayName = CompanyUspSection;

export default CompanyUspSection;
